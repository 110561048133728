import * as React from 'react'
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';

export default function blockedPage() {
  return (
    <BaseLayout>
        <Seo title="Blocked" />
        <section className='not__found'>
          <p className='blocked'>The website is not available in your region</p>
        </section>
    </BaseLayout>
  )
}
